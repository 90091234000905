'use strict';

$(document).ready(function() {

  $('.header__nav-toggle').click(function(){
    $('.header__nav').toggleClass('header__nav--open');
    $('.header').toggleClass('header--open');
  });

  $('.map__btn').magnificPopup({
    type: 'inline',
    preloader: false,
    modal: true
  });

  $(document).on('click', '.js-modal-close', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });

  $('.map__btn').click(function(){
    $('.js-slider').slick('refresh');
  });

  $('.js-slider').slick({
    dots: true,
    dotsClass: 'modal-content__slider-dots',
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    swipe: false
  });
});